<template>
	<div class="">
		<router-view></router-view>
	</div>
</template>

<script>
	export default{
		data(){
			return{
			}
		},
		methods:{
			
		}
	}
</script>

<style lang="scss" scoped>
	
	
</style>